const BranchData = {
  title: "Location",
  BranchDetails: [
    {
      title: "Kolkata",
      key: "East India",
      Address:
        "4th Floor, Unit No. - 19, Chowringhee Court 55/1, Chowringhee Road, Kolkata – 700071.",
      TelNo: "+91 33 - 40357070",
      lat_long: [22.559638066454298, 88.35089371262761],
    },
    {
      title: "Gangtok",
      key: "East India",
      Address:
        "Aketh Lee, near SDF Bhawan, Indira Bypass, Gangtok, Sikkim – 737102.",
      TelNo: "",
      lat_long: [27.326483056872256, 88.60776701872787],
    },
    {
      title: "Indore",
      key: "North India",
      Address: `402 4th floor Royal Estate Building 9/2, South Tukoganj Opp Sahaj hospital Indore 452001 MP India`,
      TelNo: "+91 9039002804",
      lat_long: [22.72123731456143, 75.88147543447552],
    },
    {
      title: "New Delhi",
      key: "North India",
      Address: `6th Floor, Agarwal Corporate Tower Rajendra Place, New Delhi – 110008`,
      TelNo: "",
      lat_long: [28.645166294056242, 77.17757023843797],
    },
    {
      title: "Udaipur",
      key: "North India",
      Address:
        "Shukarana, No. 6 1st floor Durga Nursery Road, Near Sukhadia Memorial, Udaipur - 313001.",
      TelNo: "+91 9071577493",
      lat_long: [24.585635181834235, 73.70947845499664],
    },
    // {
    //   title: "Gurgaon",
    //   key: "North India",
    //   Address:
    //     "Unit # 144 & 145, 1st Floor, Centrum Plaza, Sector - 53, Gurgaon, Haryana - 122002.",
    //   TelNo: "+91 124 - 4543000",
    //   lat_long: [28.43447588798104, 77.10369829741062],
    // },
    // {
    //   title: "Mohali",
    //   key: "North India",
    //   Address:
    //     "ST Square Building Plot No C 167, Office no 204-206 First floor, Industrial area, Phase 8 B, SAS Nagar Mohali, PIN 140308",
    //   TelNo: "+91 172 - 4917000",
    //   lat_long: [30.71076357708281, 76.69301794349352],
    // },
    // {
    //   title: "Rudrapur",
    //   key: "North India",
    //   Address:
    //     "Shop No. 21,1st Floor, SGAD Complex, Near Vishal Mega Mart, Nainital Highway, Rudrapur, U S Nagar (UK) – 263153",
    //   TelNo: "+91 5944 - 690009 / 690690",
    //   lat_long: [28.981306819745807, 79.39906767593564],
    // },
    {
      title: "Jaipur",
      key: "North India",
      Address:
        "S-9, 2nd Floor, Jagdamba Tower, Amrapali Circle, Vaishali Nagar, Jaipur - 302021",
      TelNo: "",
      lat_long: [26.911353280203773, 75.74429127011238],
    },
    {
      title: "Lucknow",
      key: "North India",
      Address:
        "2nd floor, Cyber Heights, 204 Vibhuti Khand, Gomtinagar, Lucknow - 226010",
      TelNo: "",
      lat_long: [26.869604885736027, 81.00934928046098],
    },
    {
      title: "Hyderabad",
      key: "South India",
      Address:
        "H.No.8-2-248/1/757/57A, Plot.57, Nagarjuna Hills, Corporation Bank Building, Punjagutta, Hyderabad - 500082, Telangana.",
      TelNo: "+91 40 - 67868800",
      lat_long: [17.42715686100451, 78.44836673952705],
    },
    {
      title: "Bangalore (Head Office)",
      key: "South India",
      Address:
        "No 73/1, 13th Floor, Summit B, Brigade Metropolis, Garudachar Palya, Mahadevapura Post, Whitefield Main Road, Bengaluru – 560048",
      TelNo: "+91 80 - 68407000",
      lat_long: [12.992528634464659, 77.70322700788414],
    },
    {
      title: "Chennai",
      key: "South India",
      Address:
        "# 47, Montieth Place, 2nd & 3rd Floor, Montieth Road, Egmore, Chennai - 600008.",
      TelNo: "+91 44 - 71113334",
      lat_long: [13.066777613704163, 80.26048771063458],
    },
    // {
    //   title: "Coimbatore",
    //   key: "South India",
    //   Address:
    //     "No: 151 B, Aditya Complex,2nd Floor, Room No: 201, Above Federal Bank, NSR Road, Saibaba colony, Coimbatore - 641011",
    //   TelNo: "+91 9071577324",
    //   lat_long: [11.03142684328301, 76.94647849016168],
    // },
    {
      title: "Trivandrum",
      key: "South India",
      Address:
        "No 11/766-2, Ground Floor, Krishna Tower Building, Plamoodu, Trivandrum – 695004",
      TelNo: "+91 9061026440",
      lat_long: [8.512230322495629, 76.94384717781755],
    },
    {
      title: "Mumbai",
      key: "West India",
      Address:
        "1007/1008, 10th Floor, Windfall Building, Sahar Plaza, Andheri-Kurla Road, JB Nagar, Andheri (E), Mumbai – 400 059",
      TelNo: "+91 22 - 48925500",
      lat_long: [19.113454955008184, 72.86944004495437],
    },
    {
      title: "Pune",
      key: "West India",
      Address:
        "#Unit No. 16, 17 & 18, 2nd Floor, Aditya Centeegra, FC Road, Shivajinagar, Pune – 411004",
      TelNo: "+91 20 - 66838100",
      lat_long: [18.526252340929286, 73.84158643834495],
    },
    {
      title: "Ahmedabad",
      key: "West India",
      Address:
        "B-402, 4th Floor, Sankalp Iconic Tower, Opp Vikram Nagar, Iscon Temple Cross Road, S.G Highway, Ahmedabad-380054.",
      TelNo: "+91 9071378122",
      lat_long: [23.097777434607128, 72.53091429506205],
    },
    {
      title: "Baroda",
      key: "West India",
      Address:
        "1st Floor, Nitya Building, Near Radha Krishna Cross Road, Akota, Vadodara-390020.",
      TelNo: "+91 9033009207",
      lat_long: [22.293212063424058, 73.16661771164343],
    },
    {
      title: "Goa",
      key: "West India",
      Address:
        "2G, 2nd floor, Building no 4, Sesa Ghor , Plot No -20, Patto, Panaji, Goa – 403001.",
      TelNo: "+91 9028002043 / 9028001690",
      lat_long: [15.49724516257451, 73.83346591452327],
    },
  ],
}

export default BranchData
